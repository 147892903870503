

.speaklabs-activity-platform-chip {
  height: 24px !important;
  margin-bottom: .1rem;
  margin-right: .15rem;
}

.speaklabs-activity-platform-chip span.MuiChip-label{
  padding-left: .5rem ;
  padding-right: .5rem ;
  font-size: 12px;
}