

.speaklabs-chat-message-list {
  
}

#speaklabs-chat-screen-send-message-row-grid {
  /* height: 45px !important; */
}

#speaklabs-chat-messages-container {
  overflow-y: scroll;
}



.speaklabs-incoming-message-column-stack,
   .speaklabs-my-message-column-stack {
  max-width: 70%;
}

.speaklabs-audio-stack .speaklabs-chat-message-text-span {
  /* width: 100% !important;
  display: block !important; */
}


.speaklabs-audio-stack .speaklabs-incoming-message-column-stack .speaklabs-chat-message-text-span {
  /* background-color: yellow; */
  width: calc( 100% - .5rem);
}




.speaklabs-chat-message-text-span, 
  .speaklabs-chat-my-message-text-span {
  /* background-color: #ddd; */
  background-color: white;
  padding: .25rem;
  /* max-width: 70%; */  
  border-radius: .5rem;
  color: #303030;
  font-size: 14px;
  line-height: 18px
}
.speaklabs-chat-message-text-span {
  min-width: 195px;
}
.speaklabs-chat-my-message-text-span {
  background-color: rgb(220,248,198); /* whatsapp color hehe */  

}

.speaklabs-message-sender-span {
  display: block;
  color: red;
}
.speaklabs-message-sender-flex-span {
  display: flex;
  justify-content: space-between;
}
.speaklabs-message-sender-button {
  color: rgb(25, 118, 210);
  text-decoration: underline;
  margin-left: .5rem;
  margin-right: .5rem;
}
.speaklabs-message-sender-button:hover {
  cursor: pointer;
}

.speaklabs-chat-answered-message {
  display: block;
  /* background-color: red;   */
  /* background-color: rgb(247,247,247);   */
  /* background-color: #f7f7f7;   */
  background-color: #f2f2f2;  
  padding: .15rem;
  border-left: 4px rgb(223,182,16) solid;
  border-radius: .35rem;
  color: rgba(0,0,0, 0.6);
  font-size: 13px;
}
.speaklabs-chat-answered-message:hover {
  cursor: pointer;  
  background-color: #e5e5e5;
}

.speaklabs-chat-message-answered-message-sender {
  display: block;
  color: rgb(223,182,16);
}
.speaklabs-chat-answered-message-span {
  display: block;
  max-lines: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
}

.speaklabs-chat-message-time-span {
  color: rgba(0, 0, 0, 0.45);
  font-size: 0.75rem;
}

#speaklabs-chat-screen-send-message-row-grid .MuiOutlinedInput-root {
  padding-top: 11px;
  padding-bottom: 11px;
}

