/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


/* THIS IS FOR ALERTS  */
:root {
  --primary-color: #17a2b8;
  --dark-color: #343a40;
  --light-color: #f4f4f4;
  --danger-color: #dc3545;
  --success-color: #28a745;
  --warning-color: #ffc107;

  --distance-1: .5rem;
  --distance-2: 1rem;
  --distance-3: 1.5rem;
  --distance-4: 2rem;
  --distance-5: 3rem;
  --distance-6: 4rem;
  --distance-7: 5rem;
  --distance-8: 6rem;
}
/* END OF THIS IS FOR ALERTS  */




/* MARGIN CLASSES */
/* Margin and Padding 0 */
.mp-0 {
  margin: 0 !important;
  padding: 0 !important;
}
.m-auto {
  margin: auto !important;
}

/* Left */
.ml-1  { margin-left: var( --distance-1 ) !important; }
.ml-2  { margin-left: var( --distance-2 ); }
.ml-3  { margin-left: var( --distance-3 ); }
.ml-4  { margin-left: var( --distance-4 ); }
/* Top */
.mt-1  { margin-top: var( --distance-1 ) !important; }
.mt-2  { margin-top: var( --distance-2 ) !important; }
.mt-3  { margin-top: var( --distance-3 ) !important; }
.mt-4  { margin-top: var( --distance-4 ) !important; }
/* Right */
.mr-1  { margin-right: var( --distance-1 ) !important; }
.mr-2  { margin-right: var( --distance-2 )   !important;}
.mr-3  { margin-right: var( --distance-3 )  !important;}
.mr-4  { margin-right: var( --distance-4 ) !important;}
/* Bottom */
.mb-1  { margin-bottom: var( --distance-1 ) !important }
.mb-2  { margin-bottom: var( --distance-2 ) !important; }
.mb-3  { margin-bottom: var( --distance-3 ) !important; }
.mb-4  { margin-bottom: var( --distance-4 ) !important; }
/* Horizontal */
.mx-1  { margin-right: var( --distance-1 ); margin-left: var( --distance-1 );}
.mx-2  { margin-right: var( --distance-2 ) !important; margin-left: var( --distance-2 ) !important;}
.mx-3  { margin-right: var( --distance-3 ); margin-left: var( --distance-3 );}
.mx-4  { margin-right: var( --distance-4 ); margin-left: var( --distance-4 );}
/* Vertical */
.my-1  { margin-top: var( --distance-1 ) !important; margin-bottom: var( --distance-1 ) !important;}
.my-2  { margin-top: var( --distance-2 ) !important; margin-bottom: var( --distance-2 ) !important;}
.my-3  { margin-top: var( --distance-3 ) !important; margin-bottom: var( --distance-3 ) !important;}
.my-4  { margin-top: var( --distance-4 ); margin-bottom: var( --distance-4 );}
/*  PADDING  */
/* Left */
.pl-1  { padding-left: var( --distance-1 ) !important; }
.pl-2  { padding-left: var( --distance-2 ); }
.pl-3  { padding-left: var( --distance-3 ); }
.pl-4  { padding-left: var( --distance-4 ); }
/* Top */
.pt-1  { padding-top: var( --distance-1 ) !important; }
.pt-2  { padding-top: var( --distance-2 ) !important; }
.pt-3  { padding-top: var( --distance-3 ) !important; }
.pt-4  { padding-top: var( --distance-4 ) !important; }
.pt-5  { padding-top: var( --distance-5 ); }
.pt-6  { padding-top: var( --distance-6 ); }
/* Right */
.pr-1  { padding-right: var( --distance-1 ) !important; }
.pr-2  { padding-right: var( --distance-2 ); }
.pr-3  { padding-right: var( --distance-3 ); }
.pr-4  { padding-right: var( --distance-4 ); }
.pr-5  { padding-right: var( --distance-5 ); }
.pr-6  { padding-right: var( --distance-6 ); }
/* Bottom */
.pb-1  { padding-bottom: var( --distance-1 ); }
.pb-2  { padding-bottom: var( --distance-2 ); }
.pb-3  { padding-bottom: var( --distance-3 ); }
.pb-4  { padding-bottom: var( --distance-4 ); }
/* Horizontal */
.px-1  { padding-right: var( --distance-1 ); padding-left: var( --distance-1 );}
.px-2  { padding-right: var( --distance-2 ) !important; padding-left: var( --distance-2 ) !important;}
.px-3  { padding-right: var( --distance-3 ); padding-left: var( --distance-3 );}
.px-4  { padding-right: var( --distance-4 ); padding-left: var( --distance-4 );}
/* Vertical */
.py-1  { padding-top: var( --distance-1 ); padding-bottom: var( --distance-1 );}
.py-2  { padding-top: var( --distance-2 ) !important; padding-bottom: var( --distance-2 ) !important;}
.py-3  { padding-top: var( --distance-3 ); padding-bottom: var( --distance-3 );}
.py-4  { padding-top: var( --distance-4 ); padding-bottom: var( --distance-4 );}
.py-5  { padding-top: var( --distance-5 ); padding-bottom: var( --distance-5 );}
.py-6  { padding-top: var( --distance-6 ); padding-bottom: var( --distance-6 );}
/* All Directions */
.p-1  { padding: var( --distance-1 ) !important; }
.p-2  { padding: var( --distance-2 ); }
.p-3  { padding: var( --distance-3 ); }
.p-4  { padding: var( --distance-4 ); }
.p-5  { padding: var( --distance-5 ); }
.p-6  { padding: var( --distance-6 ); }
.p-7  { padding: var( --distance-7 ); }
.p-8  { padding: var( --distance-8 ); }
/* END Of MARGIN CLASSES */


.font-weight-bold {
  font-weight: bold;
}




/* Flexbox */
.flexrow {
  display: flex;
  flex-direction: row;
}
.flexcol {
  display: flex;
  flex-direction: column;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-flex-end {
  justify-content: flex-end;
}
.justify-content-flex-start {
  justify-content: flex-start;
}
.justify-content-space-around {
  justify-content: space-around;
}
.align-items-flex-end {
  align-items: flex-end;
}
.align-items-center {
  align-items:center;
}

/* End of Flexbox */

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100%;
}




.text-center {
  text-align: center;
}
.text-bold {
  font-weight: bold;
}
.text-white {
  color: #fff;
}



/* ALERT  */
.alert {
  padding: 0.8rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}
.alert-primary {
  background: var(--primary-color);
  color: #fff;
}
.alert-light {
  background: var(--light-color);
  color: #333;
}
.alert-dark {
  background: var(--dark-color);
  color: #fff;
}
.alert-danger {
  background: var(--danger-color);
  color: #fff;
}
.alert-success {
  background: var(--success-color);
  color: #fff;
}
.alert-warning {
  background: var(--warning-color);
  color: #fff;
}
.alert-white {
  background: #fff;
  color: #333;
  border: #ccc solid 1px;
}



.page-footer {
  background-color: #424242 !important;
}



.d-block {
  display: block;
}
.d-inline-block {
  display: inline-block;
}
.d-none {
  display: none;
}


.mbs-0 {
  margin-block-start: 0 !important;
}
.mbe-0 {
  margin-block-end: 0 !important;
}


.hover-cursor-pointer:hover {
  cursor: pointer;
}
.hover-cursor-pointer-change-bg-color:hover {
  cursor: pointer;
  background-color: rgb(235, 235, 235);
}

#speaklabs-sticky-navbar-button {
  position: fixed; /* Safari */
  /* position: sticky; */
  padding-left: 1rem;
  top: 60;
  left: 80;
  z-index: 50;
}


.screen-right-top-sticky-icon-button {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  right: 0;
}



/* SMALLER CHIP */
.speaklabs-smaller-custom-chip {
  height: 24px !important;
  margin-bottom: .1rem;
}

.speaklabs-smaller-custom-chip span.MuiChip-label{
  padding-left: .5rem ;
  padding-right: .5rem ;
  font-size: 12px;
}
/* End of SMALLER CHIP */


#speaklabs-date-range-picker input {
  padding: 8px !important;
  line-height: 1 !important;
  color: red;
}



.global-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
  opacity: 1;
  transition: opacity .3s ease-in;
}

.global-loader.global-loader--hidden {
    opacity: 0;
    pointer-events: none;
}

.d-flex {
  display: flex;
}

.form-control-label {
  position: static !important;
  transform: none !important;
}

.select-field-wrapper .MuiSelect-select {
  padding: 10.5px 14px !important;
}