

#manidar-left-drawer a span.MuiButton-label {
  color: rgb(250, 250, 250);
}

#manidar-left-drawer a.active span.MuiButton-label {
  color: #f50057;
}

