
.full-control div.seek {
  /* display: flex; */
  /* justify-content: space-between;
  align-items: center; */
  /* height: 40px; */
}

.full-control div.seek .slider-container {
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%; */
  display: block;
  width: 100%;
  /* width: calc( 100% - 50px ); */
  /* height: 40px !important; */
  /* background-color: yellow !important; */
  padding-top: 12px;
  margin-top: 0 !important;
}

.full-control div.seek .slider-container input {
  display: inline-block;
  width: 100%;
  background-color: rgb(128, 12, 31);
}