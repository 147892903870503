.filterDateWrapper {
    display: flex;
    gap: 10px;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.select {
    min-width: 220px;

}

.select :global(.MuiSelect-select) {
    padding: 10.5px 14px;
}