

.custom-datetime-2 input {
  background-color: #ccc !important;
  padding: 1rem;
  border-radius: .35rem;
  border: 1px rgba(0,0,0, .4),  solid !important;
  font-size: 16px;
  font-weight: lighter;
  color: rgba(0,0,0, .9);  
  border-top-width: 1px;
  border-top-color: rgba(0,0,0, .4);
  border-bottom-width: 1px;
  border-bottom-color: rgba(0,0,0, .4);
  border-left-width: 1px;
  border-left-color: rgba(0,0,0, .4);
  border-right-width: 1px;
  border-right-color: rgba(0,0,0, .4);
}


/* when clicked -> */
.custom-datetime-2 input.react-datepicker-ignore-onclickoutside {
  /* mimic material ui */
  outline-color: rgba(25, 118, 210, 1) !important;
}
/* End of  -> when clicked  */

.react-datepicker-popper {
  z-index: 50;
}